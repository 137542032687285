export const targetTypeConfig = {
  appointment: { label: '服務項目', value: 'appointment' },
}

export const addContentTemplateTypeOptions = {
  // systemTemplate: { label: '以系統預設範本進行修改', value: 'systemTemplate' },
  customImageText: { label: '自定義圖文資訊', value: 'customImageText' },
}

export const systemContentTemplateOptions = {
  appointmentInfo: { label: '(預約) 系統預設 - 預約資訊範本', value: 'appointmentInfo' },
  shopInfo: { label: '(通用) 系統預設 - 店家資訊範本', value: 'shopInfo' },
  imageText: { label: '(通用) 系統預設 - {圖文資訊範本名稱}', value: 'imageText' },
}

export const contentTemplateTypeOptions = {
  default: { label: '系統預設', value: 'default' },
  create: { label: '新增', value: 'create' },
}

export const systemContentTemplateConfig = {
  appointmentInfo: { label: '預約', value: 'appointmentInfo' },
  shopInfo: { label: '通用', value: 'shopInfo' },
  imageText: { label: '通用', value: 'imageText' },
}

export const useTypeOptions = {
  universal: { label: '通用', value: 'shopInfo,imageText' },
  appointment: { label: '預約', value: 'appointmentInfo' },
}

export const appointmentInfoConfigTypes = {
  showUserName: '服務對象',
  showOrderCode: '訂單編號',
  showMemberName: '會員姓名',
  showServiceName: '服務項目',
  showUserAddress: '服務地址',
  showSubServiceName: '子項目',
  showCustomFieldKeys: '自定義欄位鍵',
  showAppointmentUnitName: '服務人員 (預約純設備服務不顯示)',
  showAppointmentStartDate: '預約日期',
  showAppointmentStartTime: '預約時間',
  showAppointmentPeopleCount: '預約人數',
  showAppointmentUserComment: '會員預約備註',
  showAppointmentResourceName: '服務設備 (預約純設備服務才顯示)',
  showAppointmentServiceAttach: '附加服務',
  showAppointmentOrderDepositStatus: '付款狀態',
  showAppointmentTotalBookingTime: '預約時長',
}

export const reservationNotificationSettingTypeConfigs = {
  appointmentCreate: { label: '會員建立預約', value: 'appointmentCreate', configLabel: '預約建立', limitCount: 1, omitList: ['before', 'after'] },
  appointmentChange: { label: '會員變更預約', value: 'appointmentChange', configLabel: '預約變更', limitCount: 1, omitList: ['before', 'after'] },
  appointmentCancel: { label: '會員取消預約', value: 'appointmentCancel', configLabel: '預約取消', limitCount: 2, omitList: ['before'] },
  appointmentStart: { label: '服務開始前', value: 'appointmentStart', configLabel: '預約提醒', limitCount: 3, omitList: ['now', 'after'] },
  appointmentEnd: { label: '服務結束後', value: 'appointmentEnd', configLabel: '預約結束', limitCount: 3, omitList: ['before'] },
}

export const contentBlockTypeConfigs = {
  appointmentInfo: { label: '預約資訊', value: 'appointmentInfo' },
  shopInfo: { label: '店家資訊', value: 'shopInfo' },
  imageText: { label: '圖文資訊', value: 'imageText' },
}

export const contentActionConfigs = {
  openLink: { placeholder: '請輸入連結', value: 'openLink' },
  openClientAppointmentOrder: { placeholder: '點擊後：前往預約紀錄', value: 'openClientAppointmentOrder' },
  confirmMessage: { placeholder: '前往 {日期}{服務項目}{訂單編號}', value: 'confirmMessage' },
  addGoogleCalendar: { placeholder: '點擊後：日期加入 google 行事曆', value: 'addGoogleCalendar' },
}

export const targetConditionTypeConfigs = {
  null: { label: '無', value: null },
  firstNotify: { label: '首次預約通知對象範本包含的服務項目', value: 'firstNotify' },
  firstOrder: { label: '首次預約', value: 'firstOrder' },
}

// 建立預約
export const appointmentCreateContentBlockActions = [
  {
    enabled: false,
    style: 'large',
    type: 'addGoogleCalendar',
    label: '連結 Google 行事曆',
  },
  {
    enabled: false,
    style: 'large',
    type: 'openLink',
    label: null,
    linkUrl: null,
  },
  {
    enabled: false,
    style: 'small',
    type: 'openClientAppointmentOrder',
    label: '查看預約紀錄',
  },
]
// 變更預約
export const appointmentChangeContentBlockActions = [
  {
    enabled: false,
    style: 'large',
    type: 'addGoogleCalendar',
    label: '連結 Google 行事曆',
  },
  {
    enabled: false,
    style: 'large',
    type: 'openLink',
    label: null,
    linkUrl: null,
  },
  {
    enabled: false,
    style: 'small',
    type: 'openClientAppointmentOrder',
    label: '查看預約紀錄',
  },
]
// 取消預約建立
export const appointmentCancelContentBlockActions = [
  {
    enabled: false,
    style: 'large',
    type: 'openLink',
    label: null,
    linkUrl: null,
  },
  {
    enabled: false,
    style: 'large',
    type: 'openLink',
    label: null,
    linkUrl: null,
  },
  {
    enabled: false,
    style: 'small',
    type: 'openLink',
    label: null,
    linkUrl: null,
  },
]
// 服務開始前
export const appointmentStartContentBlockActions = [
  {
    enabled: false,
    style: 'large',
    type: 'confirmMessage',
    label: '確認前往',
  },
  {
    enabled: false,
    style: 'large',
    type: 'openLink',
    label: null,
    linkUrl: null,
  },
  {
    enabled: false,
    style: 'small',
    type: 'openClientAppointmentOrder',
    label: '查看預約紀錄',
  },
]

export const appointmentContentActionsConfig = {
  appointmentCreate: appointmentCreateContentBlockActions,
  appointmentChange: appointmentChangeContentBlockActions,
  appointmentCancel: appointmentCancelContentBlockActions,
  appointmentStart: appointmentStartContentBlockActions,
}
